<template>
    <b-modal size="xl"
             centered
             hide-footer
             hide-header
             body-class="position-relative p-1"
             v-model="value"
             v-on:hide="hideModal"
             v-on:cancel="hideModal"
             v-on:close="hideModal">
        <button class="position-absolute btn-close btn btn-outline-secondary rounded-circle p-0"
                v-on:click="hideModal">
            <font-awesome-icon class="w-100 h-100"
                               icon="times-circle">
            </font-awesome-icon>
        </button>
        <div class="video-wrapper rounded overflow-hidden">
            <b-embed type="iframe"
                     aspect="16by9"
                     v-bind:src="videoSrc"
                     allowfullscreen>
            </b-embed>
        </div>
    </b-modal>
</template>

<script>
    import {
        BModal,
        BEmbed,
    } from "bootstrap-vue";

    export default {
        name: "ModalYoutubeVideo",
        components: {
            BModal,
            BEmbed,
        },
        props: {
            "value": {
                type: Boolean,
                default: false,
            },

            "videoSrc": {
                type: String,
                required: true
            },
        },
        data () {
            return {

            };
        },
        computed: {

        },
        methods: {
            hideModal () {
                this.$emit("input", false);
            },
        },
    }
</script>

<style lang="scss" scoped>
    .btn-close {
        top: -15px;
        right: -15px;

        width: 36px;
        height: 36px;

        background-color: rgba(255, 255, 255, 1);
        border-width: 3px;
        border-color: rgba(255, 255, 255, 1);

        z-index: 1;

        &:hover,
        &:focus {
            background-color: #6c757d;
        }
    }
</style>
