<template>
    <article class="emsculpt-v2 overflow-hidden">
        <emsculpt-home-v2></emsculpt-home-v2>
        <emsculpt-causes></emsculpt-causes>
        <emsculpt-functions></emsculpt-functions>
        <emsculpt-features></emsculpt-features>
        <emsculpt-feedback></emsculpt-feedback>
        <emsculpt-comparison-v2></emsculpt-comparison-v2>
        <emsculpt-faq></emsculpt-faq>
        <enquiry duplicate-to-g-sheet
                 g-sheet-id-key="branch"
                 currency="hkd"
                 amount="480"
                 show-deposit-discount-notice
                 with-extra-booking-discounts
                 v-bind:g-sheet-id="gSheetIds"
                 v-bind:extra-booking-discounts="extraBookingDiscounts"
                 v-bind:treatment-id="treatmentId">
            免費專業美容顧問咨詢 及 安排首次半價體驗
            <template v-slot:discount>
                {{ currentMonth }}月份尊享訂金折扣優惠，客戶於登記時同時以PayMe繳付訂金，即可獲得與訂金同等價值之折扣。
                例子：您為EmSculpt療程支付了$100訂金，我們將會在應收的體驗價格 - $480，提供$100扣減優惠，即是次體驗價為$380。當日療程亦只需在接金外繳付單價$280。
                EmSculpt每次登記最高可享優惠$100，Ultra FEMME 360優惠上限為$500。
            </template>
        </enquiry>
        <btn-register-now></btn-register-now>
    </article>
</template>

<script>
import Enquiry from "./Enquiry";
import EmsculptFaq from "./EmsculptV2/Faq";
import EmsculptHomeV2 from "./EmsculptV2/Home";
import EmsculptFeatures from "./EmsculptV2/Features";
import EmsculptCauses from "./EmsculptV2/Causes";
import EmsculptFunctions from "./EmsculptV2/Functions";
import EmsculptComparisonV2 from "./EmsculptV2/Comparison";
import EmsculptFeedback from "./EmsculptV2/Feedback";
import BtnRegisterNow from "./EmsculptV2/BtnRegisterNow";

const monthMap = [
    "一", "二", "三", "四", "五", "六", "七", "八", "九", "十", "十一", "十二",
];

export default {
    name: "EmsculptV2",
    components: {
        BtnRegisterNow,
        EmsculptFeedback,
        EmsculptComparisonV2,
        EmsculptFunctions,
        EmsculptCauses,
        EmsculptFeatures,
        EmsculptHomeV2,
        EmsculptFaq,
        Enquiry,
    },
    metaInfo () {
        return {
            title: this.$sanitize(this.treatment.title[this.$i18n.locale]),
            meta: [{
                "name": "description",
                "content": this.$sanitize(this.treatment.subtitle[this.$i18n.locale]),
                "vmid": "description",
            }, {
                "property": "og:title",
                "content": this.$sanitize(this.treatment.title[this.$i18n.locale]),
                "template": "%s | marier Skincare 曼肌兒",
                "vmid": "og:title"
            }, {
                "property": "og:description",
                "content": this.$sanitize(this.treatment.subtitle[this.$i18n.locale]),
                "vmid": "og:description"
            }, {
                "property": "og:type",
                "content": "profile",
                "vmid": "og:type"
            }, {
                "property": "fb:app_id",
                "content": "148635335285458",
                "vmid": "fb:app_id"
            }, /*{
                    "property": "og:image",
                    "content": `${ this.$config.apiUrl }/${ this.$config.apiVersion }/shop/product/image/${ this.product.images[0] }`,
                    "vmid": "og:image"
                }*/]
        }
    },
    props: {
        "treatmentId": {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            "extraBookingDiscounts": [{
                amount: 800,
                value: "add-mono-sculpting-$800",
                text: `加購 <a target="_blank" href="https://www.marierskincare.com/campaign/mono-sculpting">Mono Sculpting</a> 超頻消脂修身療程 (組合試做價 +$800)`,
            }],

            "gSheetIds": {
                "尖沙咀彌敦道132號美麗華廣場A座6樓602室": "AKfycby93s7mJnKmzHMVXiFV61PW7IbdNsPZeBjdRypSQE8cybctymo8" // TST
            },

            "currentMonth": monthMap[new Date().getMonth()],
        };
    },
    computed: {
        treatment () {
            const target = this.$store.state.mall.salon.treatments.find(
                treatment => treatment._id === this.treatmentId
            );
            if (target) {
                return target;
            }
            return {
                title: {
                    "en": "",
                    "zh-hk": "",
                    "zh-cn": ""
                },
                subtitle: {
                    "en": "",
                    "zh-hk": "",
                    "zh-cn": ""
                },
                availableBranches: [],
                images: [],
                cover: "",
            };
        },
    },
    methods: {

    },
}
</script>

<style lang="scss">
@font-face {
    font-family: "Athene";
    src: url("../../assets/campaign/emsculpt/athene.otf") format("opentype");
}

.font-athene {
    font-family: "Athene", serif;
}

@import url("https://fonts.googleapis.com/css?family=Noto+Serif+TC:900&display=swap");

.font-noto-serif-tc {
    font-family: "Noto Serif TC", serif;
}
</style>
