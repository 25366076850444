<template>
    <div class="p-3">
        <div class="feedback-card card border-0 rounded-lg h-100">
            <div class="card-body">
                <div class="row no-gutters">
                    <div class="col-4 pr-2">
                        <b-aspect aspect="1:1">
                            <slot name="avatar"></slot>
                        </b-aspect>
                    </div>
                    <div class="position-relative col-8 pl-2">
                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        BAspect,
    } from "bootstrap-vue";
    export default {
        name: "FeedbackCard",
        components: {
            BAspect,
        },
        props: {

        },
        data () {
            return {

            };
        },
        computed: {

        },
        methods: {

        },
    }
</script>

<style lang="scss" scoped>
    .rounded-lg {
        border-radius: 15px !important;
    }
</style>
